import React from 'react'
import './Workflows.style.css'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

const ViewWorkflow = ({ user, loading, company }) => {
  const { id } = useParams()
  const location = useLocation()
  const data = location.state
  const navigate = useNavigate()

  console.log(data)
  if (data == null) {
    navigate('/workflows')
  }

  return (
    <div className="col-md-10 dashboard right-panel-dashboard">
      <div className="row">
        <div className="col-md-12 dashboard-main-header">
          <div className="row">
            <div className="media-body col-md-10">
              <h1 className="mt-0 mb-1">{data.title}</h1>
              <p>{data.description}</p>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-12">
              <table className="table table-striped table-borderless">
                <thead>
                  <tr>
                    <th>Workflow steps</th>
                    <th>Instructions</th>
                    <th>files</th>
                  </tr>
                </thead>
                <tbody>
                  {data.steps.map((step, index) => (
                    <tr key={index}>
                      <td className="align-middle">
                        <div className="row">
                          <div className="col-md-3 d-xs-none">
                            <i className="bi align-middle workflow-task-icon file-icon mr-3"> {index + 1}</i>
                          </div>
                          <div className="col-md-9 list-media-body align-middle">
                            <h5 className="mt-0">{step.name}</h5>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle workflow-description">{step['step_version'][0].description}</td>
                      <td className="align-middle">
                        {step['step_version'][0].type != null ? (
                          <a
                            href={step['step_version'][0].file_path}
                            target={'_blank'}
                            className="badge badge-pill badge-primary workflow-status">
                            {step['step_version'][0].short_description}
                          </a>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewWorkflow
