import React from 'react'
import logo from '../assets/img/Logo.svg'
import '../styles/header.css'
const Header = () => {
  return (
    <nav className="navbar navbar-dark bg-dark header-nav">
      <div className="row">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="" width="100" />
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Header
