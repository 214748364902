import '../../styles/addFile.css'
import React, { useState } from 'react'
import { pinNewFile } from '../../services/firebase'

//let version = [];

const AddForm = ({ user, onAdd, files, refresh, userTeams, usersInCompany }) => {
  // let [userTeams, setTeam] = useState([])
  let [fileName, setName] = useState('')
  let [fileLink, setfileLink] = useState('')
  let [fileDisc, setfileDisc] = useState('')
  let [fileDept, setFileDept] = useState('')
  let [fileDeptId, setFileDeptId] = useState('')
  let [fileParent, setfileParent] = useState('')
  let [fileParentName, setfileParentName] = useState('')
  let [isLatest, setisLatest] = useState(false)
  let [isOpen, setIsOpen] = useState(false)
  let [isParentOpen, setisParentOpen] = useState(false)
  let [isAddUserOpen, setisAddUserOpen] = useState(false)
  const [users, addUser] = useState([]);

  console.log({ userToUpdate: user })

    const findName = (key) => {
        let result = usersInCompany.find(item => item.uid === key);
        console.log(result)
        return result.name
    }
  const postFile = async () => {
    await pinNewFile(user, {
      disc: fileDisc,
      name: fileName,
      path: fileLink,
      team: fileDeptId,
      parent: fileParent,
      users: users,
    }).then((res) => {
      setIsOpen(false)
      window.location.reload(false)
      console.log(res)
    })
  }
  const onSubmit = (e) => {
    e.preventDefault()
    if (!fileName) {
      alert('Please add tast')
    }
    postFile()
  }
const addUserToFileUsers = (id) => {
   addUser([...users, id])
    console.log(users)
}

  const toggleOpen = () => setIsOpen({ isOpen: !isOpen })
  const toggleParentOpen = () => setisParentOpen({ isParentOpen: !isParentOpen })
  const toggleAddUSerOpen = () => setisAddUserOpen({ isAddUserOpen: !isAddUserOpen })
  const menuClass = `dropdown-menu${isOpen ? ' show' : ''}`
  const parentClass = `dropdown-menu${isParentOpen ? ' show' : ''}`
  const addUserClass = `dropdown-menu${isAddUserOpen ? ' show' : ''}`
  return (
    <form className="add-form" onSubmit={onSubmit}>
      <div
        className="closeBtn"
        onClick={() => {
          onAdd()
        }}
      >
        close <i className="bi bi-x-circle "></i>
      </div>
      <div className="form-group">
        <div className="row add-form-header">
          <div className="col-md-1">
            <i className="bi bi-file-earmark align-middle file-icon file-icon-add-form mr-3 file-version-view-icon"></i>
          </div>
          <div className="col-md-6 ">
            <h2 className="mt-0 align-middle add-form-title">Pin File</h2>
          </div>
        </div>
      </div>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          id="fileType"
          name="file-name"
          placeholder="File name"
          value={fileName}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="form-group">
        {/* <input type="text" className="form-control" id="fileType" aria-describedby="emailHelp" placeholder="Link to file" /> */}
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            id="fileType"
            name="file-link"
            placeholder="Link to file"
            value={fileLink}
            onChange={(e) => setfileLink(e.target.value)}
          />
          {/* <button className="input-group-text">
                        Connect
                        <i className="bi bi-google "></i>
                    </button> */}
        </div>
      </div>

      <div className="form-group">
        <textarea
          type="text"
          className="form-control"
          id="fileType"
          name="file-disc"
          placeholder="Description"
          value={fileDisc}
          onChange={(e) => setfileDisc(e.target.value)}
        />
      </div>
      <div className="form-group">
        {/* Example single danger button */}
        <div className="btn-group dashboard-add-dropdown">
          <button
            type="button"
            className="btn btn-danger dropdown-toggle"
            data-toggle="dropdown"
            aria-expanded="false"
            onClick={toggleOpen}
          >
            {fileDept || 'Department'}
          </button>
          <div className={`dropdown-menu dashboard-add-dropdown-menu ${menuClass}`}>
            <div
              className="dropdown-item"
              onClick={(e) => {
                setIsOpen(false)
                setFileDept('')
                console.log(fileDept)
              }}
            >
              Select - Department
            </div>
            {userTeams.map((team, index) => (
              <div
                key={index}
                className="dropdown-item"
                onClick={(e) => {
                  setIsOpen(false)
                  setFileDept(team.team_name)
                  setFileDeptId(team.key)
                  console.log({ fileDept: fileDept, userTeams: userTeams })
                }}
              >
                {team.team_name}
              </div>
            ))}
            {userTeams.length < 1 && (
              <div
                className="dropdown-item"
                onClick={(e) => {
                  setIsOpen(false)
                }}
              >
                You are not linked to a department
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="btn-group dashboard-add-dropdown  col-md-5">
        <button
          type="button"
          className="btn btn-danger dropdown-toggle mb-3"
          data-toggle="dropdown"
          aria-expanded="false"
          onClick={toggleParentOpen}
        >
          {fileParentName || 'Parent'}
        </button>
        <div className={`dropdown-menu dashboard-add-dropdown-menu ${parentClass}`}>
          <div
            className="dropdown-item"
            onClick={(e) => {
              setisParentOpen(false)
              // setfileParent('')
              // console.log(fileParent)
            }}
          >
            Select - Parent
          </div>
          {files.map((file, index) => (
            <div
              className="dropdown-item"
              key={index}
              onClick={(e) => {
                setisParentOpen(false)
                setfileParent(file)
                setfileParentName(file.name)
                setfileDisc(file.disc)
                setName(file.name)
                console.log(file)
              }}
            >
              {file.name}
            </div>
          ))}
        </div>
        <div className="form-check form-switch col-md-5">
          <input
            className="form-check-input check-latest"
            type="checkbox"
            role="switch"
            aria-checked
            id="flexSwitchCheckChecked"
            // checked={isLatest}
            onChange={(e) => {
              console.log(e)
              // toggleLatest()
              setisLatest({ isLatest: !isLatest })
              console.log(isLatest)
            }}
          />
          <label className="form-check-label check-latest-lable" for="flexSwitchCheckChecked">
            Latest Version{' '}
          </label>
        </div>
      </div>
        <div className="form-group">
            {/* Example single danger button */}
            <div className="btn-group dashboard-add-dropdown">
                <button
                    type="button"
                    className="btn btn-danger dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="false"
                    onClick={toggleAddUSerOpen}
                >
                    Add user to file
                </button>
                <div className={`dropdown-menu dashboard-add-dropdown-menu ${addUserClass}`}>
                    <div
                        className="dropdown-item"
                        onClick={(e) => {
                            setisAddUserOpen(false)
                            setFileDept('')
                            console.log(fileDept)
                        }}
                    >
                        Add User
                    </div>
                    {usersInCompany.map((user, index) => (
                        <div
                            key={index}
                            className="dropdown-item"
                            onClick={(e) => {
                                setisAddUserOpen(false)
                                addUserToFileUsers(user.uid)
                                // setFileDept(team.team_name)
                                // setFileDeptId(team.key)
                                // console.log({ fileDept: fileDept, userTeams: userTeams })
                            }}
                        >
                            {user.name}
                        </div>
                    ))}
                    {userTeams.length < 1 && (
                        <div
                            className="dropdown-item"
                            onClick={(e) => {
                                setisAddUserOpen(false)
                            }}
                        >
                            You are not linked to a department
                        </div>
                    )}
                </div>
            </div>
        </div>
        <div className="form-group">
            {users.map((user, index) => (
                <div className="badge badge-light add-user-badge">{findName(user)}</div>
            ))}
        </div>
      <button type="submit" className="btn btn-primary submit">
        Submit
      </button>
    </form>
  )
}

export default AddForm
