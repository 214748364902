import './style.css'
import React, {useEffect, useState} from 'react'
import {auth, createNewTeam} from '../../services/firebase'
import {useAuthState} from "react-firebase-hooks/auth";

const AddTeam = ({ user, onAdd, files, userTeams, show, company}) => {
  let [teamName, setTeamName] = useState('')

  const [currentUser, loading] = useAuthState(auth)
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
    if (currentUser) {
      console.log({
          company: company,
          currentUser: currentUser.uid,
          user: user,
      })
    }
  }, [currentUser, loading])

  const postTeam = async () => {
    await createNewTeam(currentUser.uid, {
        company: company,
        team_name: teamName,
    }).then((res) => {
      window.location.reload(false)
      console.log(res)
    })
  }

    const onSubmit = (e) => {
        e.preventDefault()
        postTeam().then((res) => {
            show(false)
        }).catch(err => {
            alert('Whoops something went wrong'+ err)
        })
    }

  return (
    <form className="add-team-form" onSubmit={onSubmit}>
      <div className="form-group">
        <div className="row add-team-header">
          <div className="col-md-12 form-spacer">
            <i className="bi bi-people align-middle file-icon file-icon-add-form file-icon-create-team-form mr-3 file-version-view-icon"></i>
              <br/>
          </div>
          <div className="col-md-12 form-spacer single-line-title">
              <h2 className="mt-0 align-middle add-form-title">Create Team</h2>
              <p className={"add-form-p"}>Invite colleagues to access and pin files on Sync</p>
          </div>
        </div>
      </div>
      <div className="form-group">
          <b className="input-team-name">Team Name</b>
          <input
            type="text"
            className="form-control"
            id="teamName"
            name="team-name"
            placeholder=""
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
          />
      </div>

      <div className="form-group">
          <div className="row add-form-content-container">
              <div className="col-md-12 add-team-button" onClick={(res) => {
                  alert('add another team clicked')
              }}>
                  <b><i className="bi bi-plus"/> Add Another Team</b>
              </div>
          </div>
      </div>
        <div className="form-group">
            <div className="row add-form-content-container">
                <div className="col-md-6 add-team-button">
                    <button className="btn btn-primary cancel" onClick={show}>
                        Cancel
                    </button>
                </div>
                <div className="col-md-6 add-team-button">
                    <button type={'submit'} className="btn btn-primary submit" onClick={onSubmit}>
                        Add Team
                    </button>
                </div>
            </div>
        </div>

    </form>
  )
}

export default AddTeam
