import React, {useEffect, useState} from 'react'
import {auth, getCompanyTeams, getProfile} from "../services/firebase";
import {useAuthState} from "react-firebase-hooks/auth";
const FileFilter = ({ filters, setFilters, filterItem, userLocal }) => {

  const addFilter = (name) => {
    setFilters([
      {
        name: name.team_name,
        value: name.key,
      },
    ])
    filterItem(name)
  }
  const clearFilters = () => {
    setFilters([
      {
        name: 'All',
        value: 'name',
      },
    ])
    filterItem('All')
  }
  const [companyTeams, setCompanyTeams] = useState([]);
  const [user, loading] = useAuthState(auth)

  useEffect(() => {
    if (loading) {
      //console.log('loading')
    }
    if (userLocal) {
      getProfile(user)
          .then((res) => {
            getCompanyTeams(res[0].company).then(teams => {
              setCompanyTeams(teams)
            })
          })
          .catch((err) => console.log(err))
    }
  }, [user, loading])
  return (
    <div className="col-md-12 filter-col">
      <div className="row">
        <div className="col-md-9">
          {filters.map((filter, filterIndex) => (
            <div key={filterIndex} className="btn-group mr-2 filter-item" role="group" aria-label="Second group">
              <button
                onClick={() => {
                  clearFilters()
                }}
                type="button"
                className="btn btn-secondary"
              >
                {filter.name}
                {filters[0].name !== 'All' && <i className="bi bi-x"></i>}
              </button>
            </div>
          ))}
          <div className="btn-group" role="group" aria-label="Third group">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle filters-dropdown"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-filter"></i> More Filters
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                {companyTeams.map((team, index) => (
                    <li key={index}>
                      <div
                          onClick={() => {
                            addFilter(team)
                          }}
                          className="dropdown-item"
                      >
                        {team.team_name}
                      </div>
                    </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FileFilter
