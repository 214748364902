import { Link } from 'react-router-dom'
import logo from '../assets/img/Logo.svg'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import '../styles/login.css'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, signInWithEmailAndPassword } from '../services/firebase'

const Login = () => {
  let [userName, setUserName] = useState('')
  let [userPassword, setUserPassword] = useState('')
  let [message, setMessage] = useState('')
  let navigate = useNavigate()
  const [user, loading] = useAuthState(auth)
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
    if (user) navigate('/dashboard')
  }, [user, loading])
  const handleLogin = async () => {
    signInWithEmailAndPassword(auth, userName, userPassword)
      .then((result) => {
        navigate('/dashboard')
        console.log(result)
      })
      .catch((err) => {
        console.log(err)
        setMessage('There was a problem logging in. Please try again later.')
      })
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 left-panel d-sm-none d-md-block">
          <img className="login-logo" src={logo} alt="logo" width="200" />
        </div>
        <div className="col-md-8 right-panel">
          <div className="row justify-content-md-center login-container">
            <div className="col-md-5 d-table">
              <div className="login-form d-table-cell align-middle">
                <h1>Welcome</h1>
                <p>Enter your email to get started</p>
                <div className="form-group">
                  <label className="email-label" htmlFor="exampleInputEmail1">
                    <small>Email</small>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="password-label" htmlFor="passwordInputPassword">
                    <small>Password</small>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="passwordInputPassword"
                    aria-describedby="passwordHelp"
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                  />
                </div>
                <p>{message}</p>
                <div
                  className="btn btn-primary login-btn"
                  aria-current="page"
                  onClick={() => {
                    handleLogin().catch((err) => console.log(err))
                  }}
                >
                  Login
                </div>
                <p className="get-started">
                  Don't have an account? <Link to="../get-started">Get started</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
