import React from 'react'
import '../styles/file.css'

const File = ({ onAdd, editTask, setShowTask, showTask, setShowEditTask, taskId, theTask, user }) => {
  //let version = [];
  // const fetchInfo = async () => {
  //     // const res = await fetch(`http://localhost:8080/get_file_versions/${theTask.id}`)
  //     const res = await fetch(`https://protean-vigil-346309.nw.r.appspot.com/get_file_versions/${theTask.id}`)
  //     const data = await res.json()
  //     setVersions(data.result);
  //     // setVersions(data)
  // }
  // fetchInfo()

  // const upDateActiveVersion = async (versionId) => {
  //   // const res = await fetch(`http://localhost:8080/update_file_version/${theTask.id}/${versionId}`)
  //   const res = await fetch(`https://protean-vigil-346309.nw.r.appspot.com/${theTask.id}/${versionId}`)
  //   const data = await res.json()
  //   await fetchInfo().then(res => console.log(data)).catch(err => console.log(err))
  // }
  // // const close = () => {
  // //     alert("close")
  // //     setShowTask(!showTask)
  // // }
  //
  // const fetchInfo = async () => {
  //   getFileById(user, taskId)
  //     .then((res) => {
  //       setVersions(res)
  //     })
  //     .catch((err) => console.log(err))
  // }
  // fetchInfo().then()
  return (
    <form className="add-form">
      <div className="form-group">
        {/* <div className="closeBtn" onClick={() => {
                close()
            }}>close <i className="bi bi-x-circle "></i></div> */}
        <div className="row add-form-header">
          <div className="col-md-1">
            <i className="bi bi-file-earmark align-middle file-icon file-icon-view mr-3 file-version-view-icon"></i>
          </div>
          <div className="col-md-11 ">
            <h2 className="mt-0 align-middle add-form-title">
              {taskId[0].name}
              <br />
            </h2>
          </div>
          <div className="col-md-12 file-disc">
            <small>{taskId[0].disc}</small>
          </div>
          <div className="col-md-3 file-disc">
            <a target="_blank" href={taskId[0].path} rel="noopener noreferrer" className="btn btn-light">
              View File
            </a>
          </div>
          <div className="col-md-3 file-disc">
            <small>{taskId[0].status}</small>
          </div>
          {
            taskId[0].versions &&
            <div className="col-md-12 file-disc">
            <small>Version history</small>
            </div>
            
          }
          
          {taskId[0].versions && taskId[0].versions.slice(0).reverse().map((version, index) => (
            <div key={index} className="col-md-12 file-disc">
              <div className="row">
                <div className="col-md-1">
                  <i className="bi bi-archive edit-icon"></i>
                </div>
                <div className="col-md-10">
                  <p>
                    {version.disc}<br/>
                    <small><b>{version.date.toDate().toDateString()}</b></small>
                  </p>
                </div>
                <div className="col-md-1">
                  <a target="_blank" href={version.path} rel="noopener noreferrer" className="btn btn-light">
                    <i className="bi bi-eye edit-icon"></i>
                  </a>
                </div>
              </div>
              {/*<div className="row">*/}
              {/*  <div className="col-md-1">*/}
              {/*    {version.status != 0 && (*/}
              {/*      <input*/}
              {/*        onChange={(res) => {*/}
              {/*          alert('changed')*/}
              {/*        }}*/}
              {/*        type={'checkbox'}*/}
              {/*        checked*/}
              {/*      />*/}
              {/*    )}*/}
              {/*    {version.status === 0 && (*/}
              {/*      <input*/}
              {/*        onChange={(res) => {*/}
              {/*          upDateActiveVersion(version.id)*/}
              {/*        }}*/}
              {/*        type={'checkbox'}*/}
              {/*      />*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*  <div className="col-md-10">*/}
              {/*    <b>{version.date}</b>*/}
              {/*    <br />*/}
              {/*    <small>{version.disc}</small>*/}
              {/*  </div>*/}
              {/*  <div className="col-md-1">*/}
              {/*    <a target="_blank" href={version.path} rel="noopener noreferrer" className="btn btn-light">*/}
              {/*      <i className="bi bi-eye edit-icon"></i>*/}
              {/*    </a>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          ))}
        </div>
      </div>

      <button type="submit" className="btn btn-primary submit" onClick={onAdd}>
        {editTask ? 'CLOSE' : 'SAVE'}
      </button>
      {/* <h6 className="btn btn-primary submit" onClick={setShowEditTask}>
                {editTask ? 'EDIT':'SAVE'}
            </h6> */}
    </form>
  )
}

export default File
