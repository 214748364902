import '../styles/dashboard.css'
import React, { useEffect, useState } from 'react'
//components
//import DashboardCards from './DashboardCards'
import Add from './Add/Add'
import FileFilter from './FileFilter'
import Files from './Files'
import { getFiles, getProfile, getTeams, getCompanyMembers } from '../services/firebase'

const Dashboard = ({
  files,
  showAddFile,
  toggleReminder,
  showAdd,
  onAdd,
  show,
  showTask,
  editTask,
  setShowEditTask,
  taskId,
  theTask,
  setFiles,
  deleteFile,
  filters,
  setFilters,
  setShowTask,
  user,
  loading,
  userTeams,
  setTeam,
  setUserProfile,
}) => {
  let [filteredFiles, setFilteredFiles] = useState([])
  const [currentUser, setCurrentUser] = useState({ name: '' })
  const [usersInCompany, setUsersInCompany] = useState([])

  const filterItem = (filterOn) => {
    
    if(filterOn === 'All') {
      setFilteredFiles(files)
    } else {
      const newItem = files.filter((newVal) => {
        return newVal.department === filterOn
      })
      setFilteredFiles([newItem])
    }
  }

  useEffect(() => {
    if (loading) {
      //console.log('loading')
      return
    }
    if (user) {
      getProfile(user)
        .then((res) => {
          // console.log({res:res, run:"getProfile has run"})
          setUserProfile(res[0])
          setCurrentUser(res[0])
          getCompanyMembers(res[0].company).then(users => {
            setUsersInCompany(users)
          })
        })
        .catch((err) => console.log(err))
    }
  }, [user, loading])

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
    if (user) {
      getFiles(user)
        .then((res) => {
          //console.log('getFiles has run')
          setFiles(res)
        })
        .catch((err) => console.log(err))
    }
  }, [user, loading])

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
    if (user) {
      getTeams(user)
        .then((res) => {
          //console.log('getTeams has run')
          setTeam(res)
        })
        .catch((err) => console.log(err))
    }
  }, [user, loading])

  return (
    <div className="col-md-10 dashboard right-panel-dashboard">
      <div className="row">
        <div className="col-md-12 dashboard-main-header">
          <div className="row">
            <div className="media-body col-md-10">
              <h1 className="mt-0 mb-1">Welcome back{', ' + currentUser.name.split(' ')[0]}</h1>
              <p>Access, track and manage your files</p>
            </div>
            <div className="col-md-2">
              <Add
                user={user}
                loading={loading}
                buttonName="Pin File"
                showAdd={showAdd}
                showTask={showTask}
                setShowTask={setShowTask}
                showAddFile={showAddFile}
                toggleReminder={toggleReminder}
                onAdd={onAdd}
                files={files}
                refresh={setFiles}
                userTeams={userTeams}
                setTeam={setTeam}
                usersInCompany={usersInCompany}
              />
            </div>
            {/*<DashboardCards filesManaged={files.length} user={user} loading={loading} />*/}
            <FileFilter filters={filters} setFilters={setFilters} filterItem={filterItem} userLocal={currentUser} />
            <Files
              files={files}
              show={show}
              showTask={showTask}
              editTask={editTask}
              setShowEditTask={setShowEditTask}
              taskId={taskId}
              theTask={theTask}
              deleteFile={deleteFile}
              filters={filters}
              filteredFiles={filteredFiles}
              user={user}
              loading={loading}
              userTeams={userTeams}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
