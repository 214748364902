import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from 'firebase/auth'
import { getFirestore, query, getDocs, collection, where, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyDLlu9c8xzKEk_4N60puU0GmdN_l1ymP7k',
  authDomain: 'protean-vigil-346309.firebaseapp.com',
  projectId: 'protean-vigil-346309',
  storageBucket: 'protean-vigil-346309.appspot.com',
  messagingSenderId: '1024297247319',
  appId: '1:1024297247319:web:ea9eacb6a8e37208e43912',
  measurementId: 'G-FV2LN55V23',
}
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)
// console.log(analytics)

const pinNewFile = async (user, data) => {
  if (!data.parent.key) {
    await addDoc(collection(db, 'files'), {
      date: new Date(),
      disc: data.disc,
      name: data.name,
      owner: user.uid,
      path: data.path,
      status: 'up to date',
      team: data.team,
      users: [user.uid, ...data.users],
    })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err
      })
  } else {
    let tempVersionArray = []
    if (!data.parent.versions) {
      tempVersionArray.push(data.parent)
    } else {
      let tempVersionObject = {
        date: new Date(),
        disc: data.disc,
        name: data.name,
        owner: user.uid,
        path: data.path,
      }
      tempVersionArray = data.parent.versions
      tempVersionArray.push(tempVersionObject)
    }
    const parentDocRef = doc(db, 'files', data.parent.key)
    await updateDoc(parentDocRef, {
      date: new Date(),
      disc: data.disc,
      name: data.name,
      owner: user.uid,
      path: data.path,
      status: 'up to date',
      team: data.team,
      users: [user.uid, ...data.users],
      versions: tempVersionArray,
    })
      .then((result) => {
        return result
      })
      .catch((err) => {
        return err
      })
  }
}

const createNewTeam = async (user, data) => {
  await addDoc(collection(db, 'teams'), {
    company: data.company,
    team_name: data.team_name,
    team_disc: '',
    members: [user],
  })
    .then((result) => {
      return result
    })
    .catch((err) => {
      return err
    })
}

const getMembers = async (company) => {
  const q = query(collection(db, 'users'), where('company', '==', company))
  let data = []
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    let tempData = { key: doc.id, ...doc.data() }
    data.push(tempData)
  })
  return data
}

const updateProfile = async (user, data) => {
  const profileDocRef = doc(db, 'users', user)
  await updateDoc(profileDocRef, data)
    .then((result) => {
      return result
    })
    .catch((err) => {
      return err
    })
}

const getProfile = async (user) => {
  const q = query(collection(db, 'users'), where('uid', '==', user.uid))
  let data = []
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    let tempData = { key: doc.id, ...doc.data() }
    data.push(tempData)
  })
  return data
}

const getFiles = async (user) => {
  const q = query(collection(db, 'files'), where('users', 'array-contains', user.uid))
  let data = []
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    let tempData = { key: doc.id, ...doc.data() }
    data.push(tempData)
  })
  return data
}

const getFileById = async (user, id) => {
  const q = query(collection(db, `files/${id}`), where('users', 'array-contains', user.uid))
  let data = []
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    let tempData = { key: doc.id, ...doc.data() }
    data.push(tempData)
  })
  return data
}

const deleteFileById = async (id) => {
  // collection(db, 'files')
  await deleteDoc(doc(db, 'files', id)).catch((err) => {
    return { err: err, message: 'something went wrong' }
  })
  return { message: 'file deleted' }
}

const getTeams = async (user) => {
  const q = query(collection(db, 'teams'), where('members', 'array-contains', user.uid))
  let data = []
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    let tempData = { key: doc.id, ...doc.data() }
    data.push(tempData)
  })
  return data
}

const getCompanyTeams = async (company) => {
  const q = query(collection(db, 'teams'), where('company', '==', company))
  let data = []
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    let tempData = { key: doc.id, ...doc.data() }
    data.push(tempData)
  })
  return data
}

const getCompanyMembers = async (company) => {
  const q = query(collection(db, 'users'), where('company', '==', company))
  let data = []
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    let tempData = { key: doc.id, ...doc.data() }
    data.push(tempData)
  })
  return data
}

const getCompanyWorkflows = async (company) => {
  const q = query(collection(db, 'workflows'), where('company', '==', company))
  let data = []
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    let tempData = { key: doc.id, ...doc.data() }
    data.push(tempData)
  })
  return data
}

const createNewWorkflow = async (data) => {
  await addDoc(collection(db, 'workflows'), data)
    .then((result) => {
      return result
    })
    .catch((err) => {
      return err
    })
}

// const getWorkflowById = async (id) => {
//   // await deleteDoc(doc(db, 'files', id)).catch((err) => {
//
//   const q = query(collection(db, `workflows/${id}`))
//   let data = []
//   const querySnapshot = await getDocs(q)
//   querySnapshot.forEach((doc) => {
//     let tempData = { key: doc.id, ...doc.data() }
//     data.push(tempData)
//   })
//   console.log(querySnapshot)
//   return querySnapshot
// }

const getWorkflowById = async (id) => {
  let data = []
  const querySnapshot = await query(collection(db, `workflows/${id}`)).get()
  querySnapshot.forEach((doc) => {
    let tempData = { key: doc.id, ...doc.data() }
    data.push(tempData)
  })
  console.log(querySnapshot)
  return querySnapshot
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    alert(err.message)
  }
}

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      name,
      authProvider: 'local',
      email,
    })
  } catch (err) {
    alert(err.message)
  }
}

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert('Password reset link sent!')
  } catch (err) {
    alert(err.message)
  }
}

const logout = () => {
  signOut(auth).catch((err) => {
    console.log(err)
  })
}

export {
  auth,
  db,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  signInWithEmailAndPassword,
  logout,
  updateProfile,
  getProfile,
  getFiles,
  getTeams,
  getFileById,
  pinNewFile,
  getMembers,
  storage,
  createNewTeam,
  getCompanyTeams,
  getCompanyMembers,
  deleteFileById,
  getCompanyWorkflows,
  getWorkflowById,
  createNewWorkflow,
}
