import './style.css'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
//components
import AddForm from '../AddForm/AddForm'
import AddTeam from '../AddTeam/AddTeam'
import AddUser from '../AddUser/AddUser'
import AddWorkflow from '../Workflows/Workflow'

const Add = ({ user, fetchUser, setUser, buttonName, onAdd, showAdd, files, refresh, userTeams, company, usersInCompany }) => {
  // let overlay = useState(false);
  let [showTask, setShowTask] = useState(false)
  const show = (id) => {
    setShowTask(!showTask)
  }
  return (
    <div className="d-grid gap-0 justify-content">
      {buttonName === 'Add Team' && (
        <button type="button" onClick={show} className="btn btn-secondary ml-3 align-end add-btn-team">
          {buttonName}
        </button>
      )}
      {buttonName === 'Add User' && (
        <button type="button" onClick={show} className="btn btn-secondary ml-3 align-start add-btn-user">
          {buttonName}
        </button>
      )}
      {buttonName === 'Workflow' && (
        <button type="button" onClick={show} className="btn btn-secondary ml-3 align-end add-btn-team">
          {buttonName}
        </button>
      )}
      {buttonName !== 'Add Team' && buttonName !== 'Add User' && buttonName !== 'Workflow' && (
        <button type="button" onClick={onAdd} className="btn btn-secondary ml-3 align-middle add-btn">
          <i className="bi bi-plus"></i> {buttonName}
        </button>
      )}
      {buttonName === 'Add Team' && showTask && (
        <div className="overlay">
          <AddTeam
            user={user}
            fetchUser={fetchUser}
            setUser={setUser}
            onAdd={show}
            files={files}
            show={show}
            refresh={refresh}
            userTeams={userTeams}
            company={company}
          />
        </div>
      )}
      {buttonName !== 'Add Team' && buttonName !== 'Add User' && buttonName !== 'Pin File' && showTask && (
        <div className="overlay">
          <AddWorkflow
            user={user}
            fetchUser={fetchUser}
            setUser={setUser}
            onAdd={show}
            files={files}
            show={show}
            refresh={refresh}
            userTeams={userTeams}
            company={company}
          />
        </div>
      )}
      {/*07450908975*/}
      {buttonName === 'Add User' && showTask && (
        <div className="overlay">
          <AddUser
            user={user}
            fetchUser={fetchUser}
            setUser={setUser}
            onAdd={show}
            files={files}
            show={show}
            refresh={refresh}
            userTeams={userTeams}
            company={company}
          />
        </div>
      )}
      {buttonName !== 'Add Team' && buttonName !== 'Add User' && showAdd && (
        <div className="overlay">
          <AddForm
            user={user}
            setUser={setUser}
            onAdd={onAdd}
            files={files}
            refresh={refresh}
            userTeams={userTeams}
            usersInCompany={usersInCompany}
          />
        </div>
      )}
    </div>
  )
}

Add.propTypes = {
  onClick: PropTypes.func,
}

export default Add
