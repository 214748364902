import './styles/globals.css'
import './styles/style.css'
import './styles/styleguide.css'
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './components/Login'
import Register from './components/Register'
import Dashboard from './components/Dashboard'
import Header from './components/Header'
import Nav from './components/Nav'
import Profile from './components/Profile'
import Teams from './components/Teams'
import Workflows from './components/Workflows/Workflows'
// import Settings from './components/Settings';
import { auth, getProfile } from './services/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import ViewWorkflow from './components/Workflows/ViewWorkflow'

function App() {
  let [showAddTask, setShowAddTask] = useState(false)
  let [showTask, setShowTask] = useState(false)
  let [editTask, setShowEditTask] = useState(true)
  let [taskId, setTaskID] = useState([])
  let [files, setFiles] = useState([])
  let [userTeams, setTeam] = useState([])
  let [userProfile, setUserProfile] = useState([])
  let [company, setCompany] = useState('')
  let [filters, setFilters] = useState([
    {
      name: 'All',
      value: 'name',
    },
  ])
  const [user, loading] = useAuthState(auth)

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
    if (user) {
      getProfile(user).then((res) => {
        setCompany(res[0].company)
      })
    }
  })
  useEffect(() => {}, [filters])

  const show = (id) => {
    setShowTask(!showTask)
    setTaskID(id)
    //console.log(taskId)
  }

  const deleteFile = (id) => {
    alert('delete that sucker')
  }

  let teams = [
    {
      id: 0,
      name: 'design',
      disc: 'This is the team that design things',
      users: [
        {
          user_id: 0,
          avatar: 'http://localhost:8080/img/size-md--placeholder-false--text-false--status-icon-false--state@2x.png',
        },
      ],
    },
  ]

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login user={user} loading={loading} />} />
        <Route path="get-started" element={<Register />} />
        <Route
          path="dashboard"
          element={
            <div className="container-fluid">
              <div className="row">
                <Header />
                <Nav userProfile={userProfile} />
                <Dashboard
                  files={files}
                  onAdd={() => setShowAddTask(!showAddTask)}
                  showAdd={showAddTask}
                  showTask={showTask}
                  setShowTask={setShowTask}
                  setFiles={setFiles}
                  show={show}
                  editTask={editTask}
                  setShowEditTask={() => setShowEditTask(!editTask)}
                  taskId={taskId}
                  theTask={files[taskId]}
                  deleteFile={deleteFile}
                  filters={filters}
                  setFilters={setFilters}
                  user={user}
                  loading={loading}
                  userTeams={userTeams}
                  setTeam={setTeam}
                  userProfile={userProfile}
                  setUserProfile={setUserProfile}
                />
              </div>
            </div>
          }
        />
        <Route
          path="profile"
          element={
            <div className="container-fluid">
              <div className="row">
                <Header />
                <Nav userProfile={userProfile} />
                <Profile files={files} user={user} loading={loading} />
              </div>
            </div>
          }
        />
        <Route
          path="teams"
          element={
            <div className="container-fluid">
              <div className="row">
                <Header />
                <Nav userProfile={userProfile} />
                <Teams teams={teams} user={user} loading={loading} />
              </div>
            </div>
          }
        />
        <Route
          path="teams/team"
          element={
            <div className="container-fluid">
              <div className="row">
                <Header />
                <Nav userProfile={userProfile} />
                <Teams files={teams} user={userProfile} loading={loading} />
              </div>
            </div>
          }
        />
        <Route
          path="settings"
          element={
            <div className="container-fluid">
              <div className="row">
                <Header />
                <Nav userProfile={userProfile} />

                <Profile
                  files={files}
                  teams={teams}
                  userLocal={user}
                  loading={loading}
                  onAdd={() => setShowAddTask(!showAddTask)}
                  showAdd={showAddTask}
                  showTask={showTask}
                  setShowTask={setShowTask}
                  setFiles={setFiles}
                  show={show}
                  editTask={editTask}
                  setShowEditTask={() => setShowEditTask(!editTask)}
                  taskId={taskId}
                  theTask={files[taskId]}
                  deleteFile={deleteFile}
                  filters={filters}
                  setFilters={setFilters}
                  user={user}
                  userTeams={userTeams}
                  setTeam={setTeam}
                  userProfile={userProfile}
                  setUserProfile={setUserProfile}
                />
              </div>
            </div>
          }
        />
        <Route
          path={'workflows'}
          element={
            <div className="container-fluid">
              <div className="row">
                <Header />
                <Nav userProfile={userProfile} />
                <Workflows user={user} loading={loading} />
              </div>
            </div>
          }
        />
        <Route
          path="/workflows/:id"
          param={'id'}
          element={
            <div className="container-fluid">
              <div className="row">
                <Header />
                <Nav userProfile={userProfile} />
                <ViewWorkflow files={teams} user={userProfile} loading={loading} company={company} />
              </div>
            </div>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
