import '../styles/files.css'
//components
import File from './File'
import React, {useEffect, useState} from 'react'
import {deleteFileById} from "../services/firebase";

const Files = ({ files, show, setShowTask, showTask, editTask, setShowEditTask, taskId, theTask, user, filters }) => {
  // const deleteThisFile = (id) => {
  //     deleteFile(id)
  // }
  //console.log(files)
  const [filteredFiles, setFilteredFiles] = useState(files);

  const filteredResults = () => {
    if (filters[0].name !== 'All') {
      const content = files.filter(file => {
        return file.team === filters[0].value;
      });
      setFilteredFiles(content)
    }
    if (filters[0].name === 'All'){
      setFilteredFiles(files)
    }
  }
  
  const deleteFile = (id) => {
    let person = prompt("Please type 'DELETE' into the field below to confirm that you would like to delete this file. Please note that this request can NOT be undone.", "");
  
    if (person === 'DELETE') {
      deleteFileById(id).then(res => {
        alert(res.message)
        window.location.reload()
      }).catch(err => console.log(err))
    }
    
  }

  useEffect(() => {
    filteredResults()
  }, [filters]);
  
  useEffect(() => {
    setFilteredFiles(files)
  }, [files]);

  return (
    <>
      {showTask && (
        <div className="overlay">
          <File
            onAdd={show}
            showTask={showTask}
            setShowTask={setShowTask}
            editTask={editTask}
            taskId={taskId}
            setShowEditTask={setShowEditTask}
            theTask={theTask}
            user={user}
          />
        </div>
      )}

      <div className="col-md-12">
        <table className="table table-striped table-borderless">
          <thead>
            <tr>
              {/* <th scope="col"  style={{'textAlign':'center'}}>#</th> */}
              <th scope="col">Files</th>
              {/*<th scope="col">Department</th>*/}
              <th scope="col">Status</th>
              {/*<th scope="col">Users</th>*/}
              {/*<th scope="col">Department</th>*/}
              <th scope="col">About</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {filteredFiles.map((file, index) => (
                <tr className="file-row" key={file.key}>
                  <td className="align-middle file-view-more">
                    <div className="row">
                      <div onClick={() => show([file])} className="col-md-3 d-xs-none">
                        <i className="bi align-middle bi-file-earmark file-icon mr-3"></i>
                      </div>
                      <div onClick={() => show([file])} className="col-md-9 list-media-body align-middle">
                        <h5 className="mt-0">{file.name}</h5>
                        {/*<p>{file.disc}</p>*/}
                      </div>
                    </div>
                  </td>
                  {/*<td className="align-middle">{file.department}</td>*/}
                  <td className="align-middle">
                    <span onClick={() => show([file])} className="badge badge-pill badge-primary form-status-up-to-date">{file.status}</span>
                  </td>
                  {/*<td className="align-middle">*/}
                  {/*    <div className="avatars-OYw5hJ">*/}
                  {/*        {file.users.map((user, userIndex) => (*/}
                  {/*            <div key={userIndex}  className={"avatar-wrap-"+ userIndex}>*/}
                  {/*                <div  className="avatar-LcVv8q border-1-5px-white">*/}
                  {/*                    <img className="avatar-f0f9Ls" src={user.avatar} alt="profile-img"/>*/}
                  {/*                </div>*/}
                  {/*            </div>*/}
                  {/*        ))}*/}
                  {/*        { file.users.length >= 5 &&*/}
                  {/*            <div className="avatar-bfxrUs border-2px-white">*/}
                  {/*                <div className="text-i2ip3A inter-medium-fuchsia-blue-12px">+{file.users.length - 5}</div>*/}
                  {/*            </div>*/}
                  {/*        }*/}
                  {/*    </div>*/}
                  {/*</td>*/}
                  <td className="align-middle">
                    <div className="media">
                      <div onClick={() => show([file])} className="media-body list-media-body">
                        <h5 className="mt-0">{file.disc}</h5>
                        <p>
                          {`${new Date(file.date.seconds).getDay()}/${new Date(file.date.seconds).getMonth()}/${new Date(
                            file.date.seconds
                          ).getFullYear()}`}{' '}
                          version
                        </p>
                      </div>
                    </div>
                  </td>
                  {/*<td className="align-middle">*/}
                  {/*  {file.team}*/}
                  {/*</td>*/}
                  <td className="align-middle">
                    <i className="bi bi-trash3 delete-icon" onClick={() => deleteFile(file.key)}></i>
                  </td>
                  <td className="align-middle">
                    <i onClick={() => show([file])} className="bi bi-eye edit-icon"></i>
                  </td>
                </tr>
            ))}
          </tbody>
        </table>
        {filteredFiles.length <= 0 && (
          <div className="alert alert-secondary col-md-12" role="alert">
            No Files Add yet. Time to get busy!!!
          </div>
        )}
      </div>
    </>
  )
}

export default Files
