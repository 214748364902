import React, { useEffect, useState } from 'react'
import './Workflows.style.css'
import Add from '../Add/Add'
import { getCompanyMembers, getCompanyTeams, getProfile, getCompanyWorkflows, auth } from '../../services/firebase'
import { Link } from 'react-router-dom'

const Workflows = ({
  files,
  showAddFile,
  toggleReminder,
  showAdd,
  onAdd,
  show,
  showTask,
  setFiles,
  setShowTask,
  user,
  loading,
  userTeams,
  setTeam,
}) => {
  const [currentUser, setCurrentUser] = useState({ name: '' })
  const [usersInCompany, setUsersInCompany] = useState([])
  const [companyTeams, setCompanyTeams] = useState([])
  const [companyWorkflows, setCompanyWorkflows] = useState([])
  let [company, setCompany] = useState('')

  useEffect(() => {
    if (loading) {
      console.log('loading')
      return
    }
    if (user) {
      console.log(user)
      getProfile(user)
        .then((res) => {
          setCurrentUser(res[0])
          setCompany(res[0].company)
          getCompanyMembers(res[0].company).then((users) => {
            setUsersInCompany(users)
          })
          getCompanyTeams(res[0].company).then((teams) => {
            setCompanyTeams(teams)
            console.log(res[0].company, teams, 'company teams has run', companyTeams)
          })
          getCompanyWorkflows(res[0].company)
            .then((workflows) => {
              setCompanyWorkflows(workflows)
              console.log({
                company: res[0].company,
                user: res[0],
                workflows: workflows,
              })
            })
            .catch((err) => console.log(err))
        })
        .catch((err) => console.log(err))
    }
  }, [user, loading])
  return (
    <div className="col-md-10 dashboard right-panel-dashboard">
      <div className="row">
        <div className="col-md-12 dashboard-main-header">
          <div className="row">
            <div className="media-body col-md-10">
              <h1 className="mt-0 mb-1">Workflows</h1>
              <p>Access, track, and manage your workflows.</p>
            </div>
            <div className="col-md-2">
              <Add
                user={user}
                loading={loading}
                buttonName="Workflow"
                showAdd={showAdd}
                showTask={showTask}
                setShowTask={setShowTask}
                showAddFile={showAddFile}
                toggleReminder={toggleReminder}
                onAdd={onAdd}
                files={files}
                refresh={setFiles}
                userTeams={userTeams}
                setTeam={setTeam}
                usersInCompany={usersInCompany}
                company={company}
              />
            </div>
            <div className="col-md-12">
              <table className="table table-striped table-borderless">
                <thead>
                  <tr>
                    <th>Workflows</th>
                    <th>Team</th>
                    <th>Status</th>
                    <th>About</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {companyWorkflows.map((workflow, index) => (
                    <tr className="workflow-row workflows-tr" key={workflow.key}>
                      <td className="align-middle">
                        <div className="row">
                          <div className="col-md-3 d-xs-none">
                            <i className="bi align-middle bi-arrow-left-right file-icon mr-3"></i>
                          </div>
                          <div className="col-md-9 list-media-body align-middle workflows-title">
                            <h5 className="mt-0">{workflow.title}</h5>
                          </div>
                        </div>
                      </td>
                      <td className="align-middle">{workflow.department}</td>
                      <td className="align-middle">
                        <span className="badge badge-pill badge-primary form-status-up-to-date">Up to date</span>
                      </td>
                      <td className="align-middle">
                        {workflow.description}
                        <br />
                        <small>{workflow.subText}</small>
                      </td>
                      <td className="align-middle">{/*<i onClick={() => alert('hello')} className="bi bi-trash edit-icon"></i>*/}</td>
                      <td className="align-middle">{/*<i onClick={() => alert('hello')} className="bi bi-pencil edit-icon"></i>*/}</td>
                      <td className="align-middle">
                        <Link aria-current="page" to={`../workflows/${workflow.key}`} state={workflow}>
                          <i className="bi bi-eye edit-icon"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {companyWorkflows.length <= 0 && (
                <div className="alert alert-secondary col-md-12" role="alert">
                  No workflows Add yet. Time to get busy!!!
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Workflows
