import { Link, useNavigate } from 'react-router-dom'
import '../styles/nav.css'
import React, { useState } from 'react'
import { logout } from '../services/firebase'

const Nav = ({ userProfile }) => {
  let navigate = useNavigate()

  //console.log({ navigator: userProfile })
  let [nav, changeNav] = useState([
    {
      name: 'Files',
      status: 'active',
      path: '../dashboard',
      icon: 'bi-stack',
      disabled: null,
      spacer: false,
      key: 1
    },
    // {
    //     name: "Teams",
    //     status: null,
    //     path: "../teams",
    //     icon: "bi-people",
    //     disabled: null,
    //     spacer: false
    //     key: 2
    // },
    {
      name: 'Workflows',
      status: null,
      path: '../workflows',
      icon: 'bi-arrow-left-right',
      disabled: null,
      spacer: false,
      key: 3
    },
    // {
    //     name: "Support",
    //     status: null,
    //     path: "../teams",
    //     icon: "bi-info-circle",
    //     disabled: "disabled",
    //     spacer: true
    //     key: 4
    // },
    {
      name: 'Settings',
      status: null,
      path: '../settings',
      icon: 'bi-gear',
      disabled: null,
      spacer: false,
      key: 5
    },
  ])
  const updateNavItem = async (i, name) => {
    let newArray = []
    await nav.forEach((element, index, array) => {
      if (index === i) {
        element.status = 'active'
        newArray.push(element)
      } else {
        element.status = null
        newArray.push(element)
      }
    })
    changeNav(newArray)
  }
  return (
    <div className="col-md-2 left-panel">
      <ul className="nav flex-column nav-v-ul">
        <li className="nav-item nav-spacer-top"></li>

        {nav.map((navItem, index) => (
            <li className={navItem.spacer ? "nav-item nav-spacer-top" : "nav-item"} key={navItem.key}>
              <Link
                onClick={(res) => {
                  updateNavItem(index, navItem.name)
                }}
                className={'nav-link ' + navItem.status + ' ' + navItem.disabled}
                aria-current="page"
                to={navItem.path}
              >
                <i className={'bi ' + navItem.icon}></i> {navItem.name}
              </Link>
            </li>
        ))}

        <li className="nav-item nav-spacer-top"></li>
        {/*<li className="nav-item nav-card-dashboard nav-notifications">*/}
        {/*    <Link className="nav-link" aria-current="page"  to="../profile">*/}
        {/*        <div className="card-body">*/}
        {/*            <h6>Notification space</h6>*/}
        {/*            <small>Place to provide users with an update or message about their account</small>*/}
        {/*        </div>*/}
        {/*    </Link>*/}
        {/*</li>*/}
        <li className="nav-item nav-profile">
          <div className="nav-link" aria-current="page">
            <div className="row">
              <div className="col-md-2">
                <img
                  className="avatar-d1QdBs avatar-dashboard-nav"
                  src={userProfile.avatar || `https://ui-avatars.com/api/?name=${userProfile.name}`}
                  alt="user-profile"
                />
              </div>
              <Link aria-current="page" to="../settings" className="col-md-5 avatar-user-details">
                <small>
                  <b>{userProfile.name}</b>
                </small>
                <br></br>
                <small>{userProfile.email}</small>
              </Link>
              <div
                className="col-md-2"
                onClick={() => {
                  logout()
                  navigate('/')
                }}
              >
                <i className="bi bi-box-arrow-right  logout"></i>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default Nav
