import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../services/firebase'
import logo from "../assets/img/Logo.svg"
import loadingImg from "../assets/img/loading.gif"
const Register = () => {
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [name, setName] = useState('')
  const [user, loading] = useAuthState(auth)
  const [processing, setProcessing] = useState(false)
  let navigate = useNavigate()
  const register = async () => {
    setProcessing(true)
    if (!name) {
      alert('Please enter name')
      setProcessing(false)
      return
    }
    if (!company) {
      alert('Please enter company name')
      setProcessing(false)
      return
    }
    if (!email)  {
      alert('Please enter email')
      setProcessing(false)
      return
    }
    const rawResponse = await fetch('https://protean-vigil-346309.nw.r.appspot.com/newCompanyAndUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        email: email,
        company: company,
      }),
    });
    const content = await rawResponse.json();
    console.log({
      rawResponse: content,
    });
    setProcessing(false)
    navigate('/')
    alert("Almost there! You will receive an email that that will help you finish the registration process")
  }
  useEffect(() => {
    if (loading) return
  }, [user, loading])
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 left-panel d-sm-none d-md-block">
          <img className="login-logo" src={logo} alt="logo" width="200" />
        </div>
        <div className="col-md-8 right-panel">
          <div className="row justify-content-md-center login-container">
            <div className="col-md-5 d-table">
              <div className="login-form d-table-cell align-middle">
                <h1>Welcome</h1>

                <p>Enter your details to get started</p>
                <div className="form-group">
                  <label className="email-label" htmlFor="exampleInputEmail1">
                    <small>Company Name</small>
                  </label>
                  <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      placeholder="Company Name"
                      required={true}
                  />
                </div>
                <div className="form-group">
                  <label className="email-label" htmlFor="exampleInputEmail1">
                    <small>Full Name</small>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Full Name"
                    required={true}
                  />
                </div>
                <div className="form-group">
                  <label className="email-label" htmlFor="exampleInputEmail1">
                    <small>Email</small>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                    required={true}
                  />
                </div>

                <button className="btn btn-primary reg-btn" onClick={register}>
                  Register
                </button>

                <div>
                  <br/>
                  Already have an account? <Link className={'login-button'} to="/">Login</Link> now.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {processing && (
        <div className={"processing"}>
          <img className="login-logo" src={ loadingImg } alt="loadingImg" width="80" />
        </div>
      )}
    </div>
  )
}

export default Register
