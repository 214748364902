import React, { useEffect, useState } from 'react'
import '../styles/dashboard.css'
import '../styles/settings.css'
import Users from './Users'
import { Countries } from '../assets/countries'
import { Timezones } from '../assets/timezones'
import {auth, getProfile, updateProfile, storage, sendPasswordReset, getMembers, getCompanyTeams} from '../services/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
// import { v4 } from "uuid";
//components

const Profile = ({ files, teams, userLocal, userTeams}) => {
  let navigate = useNavigate()
  let [nav, changeSettings] = useState([
    {
      name: 'My Details',
      status: 'active',
    },
    {
      name: 'Password',
      status: null,
    },
    {
      name: 'Users & Teams',
      status: null,
    },
  ])

  console.log({
    teams: teams,
    userTeams: userTeams,
  })

  let [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen({ isOpen: !isOpen })
  let [deptIsOpen, setDeptIsOpen] = useState(false)
  const toggleDeptOpen = () => setDeptIsOpen({ deptIsOpen: !deptIsOpen })
  let [isOpenTimezone, setIsOpenTimezone] = useState(false)
  const toggleOpenTimezone = () => setIsOpenTimezone({ isOpenTimezone: !isOpen })

  let [activeTab, changeActiveTab] = useState(0)
  let [firstname, changeFirstname] = useState('')
  let [handle, changeHandle] = useState('')
  let [email, changeEmail] = useState('')
  let [country, setCountry] = useState('')
  let [timezone, setTimezone] = useState('')
  let [role, setRole] = useState('')
  let [department, setDepartment] = useState('')
  let [avatar, setAvatar] = useState('')
  let [company, setCompany] = useState('')
  const [userKey, setUserKey] = useState('')
  const [resetEmail, setResetEmail] = useState('')
  const menuClass = `dropdown-menu${isOpen ? ' show' : ''}`
  const menuClassTimezone = `dropdown-menu${isOpenTimezone ? ' show' : ''}`
  const menuClassDept = `dropdown-menu${deptIsOpen ? ' show' : ''}`
  const [user, loading] = useAuthState(auth)
  const [users, setUsers] = useState([])
  const [fileDept, setFileDept] = useState('')
  const [fileDeptId, setFileDeptId] = useState('')
  const [companyTeams, setCompanyTeams] = useState([]);
  const [savedMessage, setSavedMessage] = useState('')

  useEffect(() => {
    if (loading) {
      //console.log('loading')
    }
    if (userLocal) {
      //console.log(userLocal.uid, true)
      getProfile(userLocal)
        .then((res) => {
          console.log(res, 'getProfile has run')
          changeEmail(res[0].email)
          changeFirstname(res[0].name)
          setAvatar(res[0].avatar)
          changeHandle(res[0].handle)
          changeEmail(res[0].email)
          setTimezone(res[0].timezone)
          setCountry(res[0].country)
          setRole(res[0].role)
          setDepartment(res[0].department)
          setCompany(res[0].company)
          setUserKey(res[0].key)
          getCompanyTeams(res[0].company).then(teams => {

            setCompanyTeams(teams)
            console.log(res[0].company, teams, 'company teams has run', companyTeams)
          })

        })
        .catch((err) => console.log(err))
    }
    if (!user) navigate('/dashboard')
  }, [])

  const postData = () => {
    updateProfile(userKey, {
      name: firstname,
      handle: handle,
      email: email,
      avatar: avatar,
      role: role,
      department: fileDeptId,
    })
      .then((data) => {
        console.log('Success:', data)
        setSavedMessage('saved')
      })
      .catch((error) => {
        console.error('Error:', error)
        setSavedMessage(error)
      })
  }

  const updateNavItem = async (i, name) => {
    let newArray = []
    await nav.forEach((element, index, array) => {
      if (index === i) {
        element.status = 'active'
        newArray.push(element)
        changeActiveTab(i)
      } else {
        element.status = null
        newArray.push(element)
      }
    })
    changeSettings(newArray)
  }
  const handleNameChange = (event) => {
    changeFirstname(event.target.value)
  }

  const handleHandleChange = (event) => {
    changeHandle(event.target.value)
  }

  const handleEmailChange = (event) => {
    changeEmail(event.target.value)
  }

  const handleResetEmail = (event) => {
    setResetEmail(event.target.value)
  }

  const handleRoleChange = (event) => {
    setRole(event.target.value)
  }

  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value)
  }

  const handleFetchUsers = async () => {
    getMembers(company)
      .then((res) => {
        setUsers(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="col-md-10 dashboard right-panel-dashboard">
      <div className="row">
        <div className="col-md-12 dashdoard-main-header">
          <div className="row">
            <div className="media-body col-md-10">
              <h1 className="mt-0 mb-1">Settings</h1>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul className="nav nav-tabs">
                  {nav.map((navItem, index) => (
                    <li key={index} className="nav-item">
                      <div
                        onClick={(res) => {
                          updateNavItem(index, navItem.name).catch((err) => console.log(err))
                          if (navItem.name === 'Users & Teams') {
                            handleFetchUsers()
                          }
                        }}
                        className={'nav-link ' + navItem.status}
                        aria-current="page"
                      >
                        {navItem.name}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              {activeTab === 0 && (
                <div className="col-md-12">
                  <h4>Personal Information</h4>
                  <p>Update your photo and personal details here.</p>
                  <hr className="hr-feild-list" />
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <b>Name</b>
                        </div>
                        <div className="col-md-8">
                          <input className="input-settings settings-input" value={firstname} onChange={(src) => handleNameChange(src)} />
                        </div>
                      </div>
                      <hr />
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <b>Handle</b>
                        </div>

                        <div className="col-md-8">
                          <input className="input-settings settings-input" value={handle} onChange={(src) => handleHandleChange(src)} />
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <b>Email</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            className="input-settings email-settings settings-input"
                            value={email}
                            onChange={(src) => handleEmailChange(src)}
                          />
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <b>Your Photo</b>
                          <br />
                          This will be displayed on your profile
                        </div>
                        <div className="col-md-1">
                          <img
                            className={'user-avatar user-avatar-img'}
                            src={avatar || `https://ui-avatars.com/api/?name=${firstname}`}
                            alt={'profile'}
                          />
                        </div>
                        <div className="col-md-7">
                          <input
                            accept="image/png, image/jpeg"
                            className="input-settings  settings-input"
                            type="file"
                            onChange={(event) => {
                              const imageRef = ref(storage, `${user.uid}/images/${event.target.files[0].name}`)
                              uploadBytes(imageRef, event.target.files[0]).then((snapshot) => {
                                console.log(`image upload: ${event.target.files[0]}`)
                                getDownloadURL(snapshot.ref).then((url) => {
                                  console.log(`the file path is: ${url}`)
                                  setAvatar(url)
                                })
                              })
                            }}
                          />
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <b>Role</b>
                        </div>
                        <div className="col-md-8">
                          <input
                            className="input-settings email-settings settings-input"
                            value={role}
                            onChange={(src) => handleRoleChange(src)}
                          />
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <b>Department</b>
                        </div>
                        <div className="col-md-8">
                          <div className="col-md-12">
                            <button
                                type="button"
                                className="btn btn-danger dropdown-toggle country-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                onClick={() => {
                                  toggleDeptOpen()
                                }}
                            >
                              {fileDept || 'Department'}
                            </button>
                            <div className={`dropdown-menu dashboard-add-dropdown-menu country-dropdown ${menuClassDept}`}>
                              <div className="dropdown-item" onClick={(e) => {
                                e.preventDefault()
                                setDeptIsOpen(false);
                                setFileDept('');
                              }}>
                                Select - Department
                              </div>
                              {companyTeams.map((team, index) => (
                                  <div
                                      key={team.key}
                                      className="dropdown-item country-item"
                                      onClick={(e) => {
                                        setDeptIsOpen(false);
                                        setFileDept(team.team_name)
                                        setFileDeptId(team.key)
                                      }}
                                  >
                                    {team.team_name}
                                  </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <b>Country</b>
                        </div>
                        <div className="col-md-8">
                          <button
                            type="button"
                            className="btn btn-danger dropdown-toggle country-toggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                              toggleOpen()
                            }}
                          >
                            {country || 'Country'}
                          </button>
                          <div className={`dropdown-menu dashboard-add-dropdown-menu country-dropdown ${menuClass}`}>
                            {Countries.map((country, index) => (
                              <div
                                key={index}
                                className="dropdown-item country-item"
                                onClick={(e) => {
                                  setIsOpen(false)
                                  setCountry(country.name)
                                }}
                              >
                                {country.name}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4">
                          <b>Timezone</b>
                        </div>
                        <div className="col-md-8">
                          <button
                            type="button"
                            className="btn btn-danger dropdown-toggle country-toggle"
                            data-toggle="dropdown"
                            aria-expanded="false"
                            onClick={toggleOpenTimezone}
                          >
                            {timezone || 'TimeZone'}
                          </button>
                          <div className={`dropdown-menu dashboard-add-dropdown-menu country-dropdown ${menuClassTimezone}`}>
                            {Timezones.map((timezone, index) => (
                              <div
                                key={index}
                                className="dropdown-item country-item"
                                onClick={(e) => {
                                  setIsOpenTimezone(false)
                                  setTimezone(timezone.value + ' (' + timezone.abbr + ') ' + timezone.abbr + ' ' + timezone.offset)
                                  console.log(timezone)
                                }}
                              >
                                {timezone.value} ({timezone.abbr}) {timezone.abbr}
                                {timezone.offset}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-1">
                      <button type="button" className="btn pWordBtn pWordBtn-save" aria-expanded="false" onClick={postData}>
                        Save
                      </button>
                    </div>
                    <div className="col-md-8">
                      <p>{savedMessage}</p>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 1 && (
                <div className="col-md-10">
                  <h2>Reset or change your password</h2>
                  <p>If you'd like to reset your password enter your email address and we'll send you a password reset link</p>
                  <hr className="hr-feild-list" />
                  <div className="row">
                    <div className="row">
                      <div className="col-md-12"></div>
                    </div>
                  </div>
                  <div className="row pWordInputs">
                    <div className="col-md-4">
                      <b>Reset Email</b>
                      <input
                        type={'email'}
                        className="input-settings email-settings settings-input"
                        value={resetEmail || email}
                        onChange={handleResetEmail}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <button
                        type="button"
                        className="btn pWordBtn pWordBtn-save"
                        aria-expanded="false"
                        onClick={(res) => {
                          if (resetEmail !== '') {
                            sendPasswordReset(resetEmail)
                              .then((result) => console.log({ result: result }))
                              .catch((err) => {
                                console.log({ err: err })
                              })
                          } else {
                            alert('Please add a reset email')
                          }
                        }}
                      >
                        Send Password Reset
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 2 && <Users company={company} users={users} userTeams={companyTeams} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
