import React, { useState } from 'react'
import './Workflows.style.css'
import { createNewWorkflow } from '../../services/firebase'

const AddWorkflow = ({ onAdd, editTask, taskId, files, company }) => {
  const [workflowName, setWorkflowName] = useState('')
  const [workflowDisc, setWorkflowDisc] = useState('')
  const [workflowSubtext, setWorkflowSubtext] = useState('')
  const [workflowTask, setWorkflowTask] = useState([])
  const [taskID, setTaskID] = useState(0)
  const [taskName, setTaskName] = useState('')
  const [taskInstruction, setTaskInstruction] = useState('')
  const [taskFile, setTaskFile] = useState('')
  const [taskFileDescription, setTaskFileDescription] = useState('')
  const [workflowFormPartTwo, setWorkflowFormPartTwo] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [stepEditing, setStepEditing] = useState(false)

  const addTask = (event) => {
    setWorkflowTask([
      ...workflowTask,
      {
        id: taskID,
        name: taskName,
        current_version: 0,
        step_version: [
          {
            description: taskInstruction,
            short_description: taskFileDescription,
            file_path: taskFile,
            type: 'file',
          },
        ],
      },
    ])
    setTaskID(Math.random())
    setTaskName('')
    setTaskInstruction('')
    setTaskFile('')
    setTaskFileDescription('')
    setWorkflowSubtext('')
  }

  const editStep = (index) => {
    setCurrentStep(index)
    setTaskID(workflowTask[index].id)
    setTaskName(workflowTask[index].name)
    setTaskInstruction(workflowTask[index].step_version[0].description)
    setTaskFile(workflowTask[index].step_version[0].file_path)
    setTaskFileDescription(workflowTask[index].step_version[0].description)
    setWorkflowSubtext('')
    setStepEditing(true)
    return
  }

  const updateCurrentStep = (index) => {
    //update workflowTask useState at specific index
    const updateWorkflowTask = {
      id: taskID,
      name: taskName,
      current_version: 0,
      step_version: [
        {
          description: taskInstruction,
          short_description: taskFileDescription,
          file_path: taskFile,
          type: 'file',
        },
      ],
    }

    const update = workflowTask.map((task, i) => (task.id === taskID ? updateWorkflowTask : task))
    setWorkflowTask(update)
    setTaskID(Math.random())
    setTaskName('')
    setTaskInstruction('')
    setTaskFile('')
    setTaskFileDescription('')
    setWorkflowSubtext('')
    setStepEditing(false)
  }

  const addNewWorkflow = () => {
    console.log({
      company: company,
      department: '',
      steps: workflowTask,
      description: workflowDisc,
      subText: workflowSubtext,
      title: workflowName,
    })
    setCurrentStep(workflowTask.length)
    if (workflowTask.length !== 0) {
      console.log('WORKFLOW CREATE FIRED')
      createNewWorkflow({
        company: company,
        department: '',
        steps: workflowTask,
        description: workflowDisc,
        subText: workflowSubtext,
        title: workflowName,
      })
        .then((r) => {
          onAdd()
        })
        .catch((err) => console.log(err))
    } else {
      alert('You need to add at least one step')
    }
  }
  return (
    <form
      className="add-form"
      onSubmit={(event) => {
        event.preventDefault()
        addNewWorkflow()
      }}>
      {/*<div*/}
      {/*  className="closeBtn"*/}
      {/*  onClick={() => {*/}
      {/*    onAdd()*/}
      {/*  }}>*/}
      {/*  close <i className="bi bi-x-circle "></i>*/}
      {/*</div>*/}
      {!workflowFormPartTwo ? (
        <>
          <div className="form-group">
            <div className="row add-form-header add-form-header-workflow">
              <div className="col-md-6 ">
                <h3 className="mt-0 align-middle add-form-title-workflow">Create a workflow</h3>
                <h6 className="mt-0 align-middle add-form-title-workflow">Add a multi-step workflow</h6>
              </div>
            </div>
          </div>

          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="fileType"
              name="file-name"
              placeholder="Workflow name"
              value={workflowName}
              onChange={(e) => setWorkflowName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="fileType"
              name="file-name"
              placeholder="Short Description"
              value={workflowSubtext}
              onChange={(e) => setWorkflowSubtext(e.target.value)}
            />
          </div>
          <div className="form-group">
            <textarea
              type="text"
              className="form-control"
              id="fileType"
              name="file-disc"
              placeholder="Detail"
              value={workflowDisc}
              onChange={(e) => setWorkflowDisc(e.target.value)}
            />
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="btn btn-secondary submit add-step-cancel" onClick={(event) => onAdd()}>
                {'Cancel'}
              </div>
            </div>
            <div className="col-md-6">
              <div className="btn btn-primary submit add-step-next" onClick={(event) => setWorkflowFormPartTwo(true)}>
                {'Add steps'}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-md-3">
              {workflowTask.length === 0 && (
                <button type="button" className="btn btn-workflow-step">
                  Step 1
                </button>
              )}
              {workflowTask.map((task, index) => (
                <button type="button" className="btn btn-workflow-step" onClick={() => editStep(index)}>
                  Step {index + 1}
                </button>
              ))}
              <button type="button" className="btn btn-workflow-step-more" onClick={(event) => addTask()}>
                <small>more +</small>
              </button>
            </div>
            <div className="col-md-9">
              <div className="form-group">
                <div>
                  <h5>Add workflow step</h5>
                  <h6>Workflow step details</h6>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control workflow-input"
                      id="fileType"
                      name="file-name"
                      placeholder="Step name"
                      value={taskName}
                      onChange={(e) => setTaskName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      type="text"
                      className="form-control workflow-input"
                      id="fileType"
                      name="file-name"
                      placeholder="Instruction"
                      value={taskInstruction}
                      onChange={(e) => setTaskInstruction(e.target.value)}
                    />
                  </div>
                  <h6>Link file to step</h6>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control workflow-input"
                      id="fileType"
                      name="file-name"
                      placeholder="File description"
                      value={taskFileDescription}
                      maxLength="20"
                      onChange={(e) => setTaskFileDescription(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control workflow-input"
                      id="fileType"
                      name="file-name"
                      placeholder="Path to file"
                      value={taskFile}
                      onChange={(e) => setTaskFile(e.target.value)}
                    />
                  </div>
                  {!stepEditing ? (
                    <div className="btn btn-primary submit add-step-cancel" onClick={(event) => addTask()}>
                      {'save step'}
                    </div>
                  ) : (
                    <div className="btn btn-primary submit add-step-cancel" onClick={(event) => updateCurrentStep(currentStep)}>
                      {'update step'}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row workflow-save">
            <div className="col-md-6">
              <div className="btn btn-primary submit add-step-cancel" onClick={(event) => setWorkflowFormPartTwo(false)}>
                {'Back'}
              </div>
            </div>
            <div className="col-md-6">
              <input className="btn btn-primary submit" value={editTask ? 'CLOSE' : 'SAVE'} type={'submit'} />
            </div>
          </div>
        </>
      )}
    </form>
  )
}

export default AddWorkflow
