import './style.css'
import React, {useEffect, useState} from 'react'
import {auth} from '../../services/firebase'
import {useAuthState} from "react-firebase-hooks/auth";
import * as image from "../../assets/img/Pulse-1s-200px.gif"

const AddUser = ({ user, onAdd, files, userTeams, show, company}) => {
    let [userName, setUserName] = useState('')
    let [userEmail, setUserEmail] = useState('')
    let [fileDept, setFileDept] = useState('')
    let [fileDeptId, setFileDeptId] = useState('')
    let [isOpen, setIsOpen] = useState(false)
    let [teams, setTeams] = useState(userTeams)
    const [addUserMessage, setAddUserMessage] = useState('')
    const menuClassDept = `dropdown-menu${isOpen ? ' show' : ''}`
    const [showLoading, setShowLoading] = useState(false)
    console.log({
        teams: userTeams
    })
    const [currentUser, loading] = useAuthState(auth)
    const toggleOpen = () => setIsOpen(!isOpen)
    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return
        }
        if (currentUser) {
            setTeams(userTeams)
            console.log({
                company: company,
                currentUser: currentUser.uid,
                user: user,
                team: fileDeptId,
                teams: userTeams
            })
        }
    }, [currentUser, loading])

    const postUser = async () => {
        const rawResponse = await fetch('https://protean-vigil-346309.nw.r.appspot.com', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: userName,
                email: userEmail,
                company: company,
                department: fileDeptId
            }),
        });
        const content = await rawResponse.json();
        console.log({
            rawResponse: content,
        });
        return {status: 'success',message:'User added'}
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setShowLoading(true)
        console.log({
            name: userName,
            email: userEmail,
            company: company,
        })
        postUser().then((res) => {
            console.log(res)
            setShowLoading(false)
            setAddUserMessage("")
            show(false)
        }).catch(err => {
            console.log(err)
            setShowLoading(false)
            setAddUserMessage("There was an issue. Please try again.")
            console.log('Whoops something went wrong', err)
        })
    }

    return (
        <form className="add-team-form" onSubmit={onSubmit}>
            <div className="form-group">
                <div className="row add-team-header">
                    <div className="col-md-12 form-spacer">
                        <i className="bi bi-people align-middle file-icon file-icon-add-form file-icon-create-team-form mr-3 file-version-view-icon"></i>
                        <br/>
                    </div>
                    <div className="col-md-12 form-spacer single-line-title">
                        <h2 className="mt-0 align-middle add-form-title">Invite a user</h2>
                        <p className={"add-form-p"}>Invite colleagues to access and pin files on Sync</p>
                    </div>
                </div>
            </div>
            <br/>
            <div className="form-group">
                <b className="input-team-name">Name</b>
                <input
                    type="text"
                    className="form-control"
                    id="userName"
                    name="user-name"
                    placeholder=""
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                />
            </div>
            <br/>
            <div className="form-group">
                <b className="input-team-name">Email</b>
                <input
                    type="email"
                    className="form-control"
                    id="userEmail"
                    name="user-email"
                    placeholder=""
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                />
            </div>
            <br/>
            <div className="form-group row">
                <div className="btn-group dashboard-add-dropdown col-md-12">
                    <button
                        type="button"
                        className="btn btn-danger dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        onClick={toggleOpen}
                    >
                        {fileDept || 'Department'}
                    </button>
                    <div className={`dropdown-menu dashboard-add-dropdown-menu dept-dropdown ${menuClassDept}`} style={{flex: 1, flexDirection: 'row',}}>
                        <div className="dropdown-item" onClick={(e) => {
                            e.preventDefault()
                            setIsOpen(false);
                            setFileDept('');
                        }}>
                            Select - Department
                        </div>
                        {teams.map((team, index) => (
                            <div
                                key={index}
                                className="dropdown-item"
                                onClick={(e) => {
                                    setIsOpen(false)
                                    setFileDept(team.team_name)
                                    setFileDeptId(team.key)
                                    console.log({ fileDept: fileDept, userTeams: userTeams })
                                }}
                            >
                                {team.team_name}
                            </div>
                        ))}
                        {teams.length < 1 && (
                            <div
                                className="dropdown-item"
                                onClick={(e) => {
                                    setIsOpen(false)
                                }}
                            >
                                You are not linked to a department
                            </div>
                        )}
                    </div>
                </div>
            </div>


            <br/>
            <div className="form-group">
                <div className="row add-form-content-container">
                    <div className="col-md-6 add-team-button">
                        <button className="btn btn-primary cancel" onClick={show}>
                            Cancel
                        </button>
                    </div>
                    <div className="col-md-6 add-team-button">
                        <button type={'submit'} className="btn btn-primary submit" onClick={onSubmit}>
                            Add User
                        </button>
                    </div>
                </div>
            </div>
            <br/><br/>
            <div className="form-group row">
                <p>{addUserMessage}</p>
                {showLoading ? (
                    <>
                    <img className={'loading'} width={'50px'} srcSet={image} alt={'loading'}/>
                    </>
                ) : "" }
            </div>
        </form>
    )
}

export default AddUser
