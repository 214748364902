import React from 'react'
import Add from './Add/Add'

const Teams = (props) => {
  return (
    <div className="col-md-10 dashboard right-panel-dashboard">
      <div className="row">
        <div className="col-md-12 dashdoard-main-header">
          <div className="row">
            <div className="media-body col-md-10">
              <h1 className="mt-0 mb-1">{props.name || 'Teams'}</h1>
              <p>View &amp; Update Your Profile</p>
            </div>
            <div className="col-md-2">
              <Add buttonName="Save" />
            </div>
            <div className="row dashboard-cards">
              {props.teams.map((team, index) => (
                <div key={team.id} className="col-md-12 teams-card">
                  <div className="card dashboard-card ">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12">
                          <h3 className="card-title">{team.name}</h3>
                          <p className="card-text">{team.disc}</p>
                          <p className="card-text stat-up">
                            <small className="small-amount">
                              This team has {team.users.length} {team.users.length > 1 ? 'members' : 'member'}
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Teams
