import React, {useCallback, useEffect, useState} from 'react'
import AddTeamMember from './AddTeamMember'
import '../styles/users.css'
import Add from './Add/Add'
import {getCompanyTeams} from "../services/firebase"

const Users = ({
  files,
  showAddFile,
  toggleReminder,
  showAdd,
  onAdd,
  showTask,
  setFiles,
  setShowTask,
  user,
  loading,
  userTeams,
  setTeam,
  users,
  company,
}) => {
  const [theTeam, setTheTeam] = useState(users);
  // users.forEach((item, index) => {
  //   userTeams.forEach((team) => {
  //     if (item.department === team.key) {
  //       console.log('FIRED', team.key, team.team_name, index)
  //       return team.team_name
  //     } else {
  //       console.log('FIRED', team, ' - ', index)
  //       return ' - '
  //     }
  //   })
  // });

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
    if (user) {
      if (company) {
        getCompanyTeams(company)
            .then((res) => {
              //console.log('getTeams has run')
              setTheTeam(res)
            })
            .catch((err) => console.log(err))
      }
    }
  }, [user, loading])

  const getTeamName = useCallback((id) => {
    const obj = userTeams.find(o => o.key === id)
    console.log(obj)
    if (obj || obj !== undefined) {
      if (obj.team_name) {
        return obj.team_name
      } else {
        return ' - '
      }
    } else {
      return ' - '
    }

  },[userTeams])
  return (
    <>
      <div className="media-body col-md-8"></div>
      <div className="col-md-2">
        <Add
            user={user}
            loading={loading}
            buttonName="Add Team"
            showAdd={showAdd}
            showTask={showTask}
            setShowTask={setShowTask}
            showAddFile={showAddFile}
            toggleReminder={toggleReminder}
            onAdd={onAdd}
            files={files}
            refresh={setFiles}
            userTeams={userTeams}
            setTeam={setTeam}
            company={company}
        />
      </div>
      <div className="col-md-2">
        <Add
          user={user}
          loading={loading}
          buttonName="Add User"
          showAdd={showAdd}
          showTask={showTask}
          setShowTask={setShowTask}
          showAddFile={showAddFile}
          toggleReminder={toggleReminder}
          onAdd={onAdd}
          files={files}
          refresh={setFiles}
          userTeams={userTeams}
          setTeam={setTeam}
          company={company}
        />
      </div>
      <div className="col-md-12 user-table-space">
        <table className="table table-borderless">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col"></th>
              {/*<th scope="col" className="align-middle th-padding">*/}
              {/*  Status*/}
              {/*</th>*/}
              <th scope="col">Role</th>
              <th scope="col">Email</th>
              <th scope="col">Teams</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr className="file-row" key={user.key} role="group" aria-label="Second group">
                <td className="align-middle user-avatar-td">
                  <img
                    className={'user-avatar'}
                    alt={'avatar'}
                    srcSet={user.avatar || `https://ui-avatars.com/api/?name=${user.name}`}
                    width={50}
                  />
                </td>
                <td className="align-middle">
                  <b>{user.name}</b> <br />
                  <small>@{user.handle}-{user.key}</small>
                </td>
                {/*<td className="align-middle pill-ul">*/}
                {/*  <div className={`pill pill-${user.status}`}>{user.status || 'inactive'}</div>*/}
                {/*</td>*/}
                <td className="align-middle">{user.role}</td>
                <td className="align-middle">{user.email}</td>
                <td className="align-middle pill-ul">
                  <div className={`pill pill-team`}>{getTeamName(user.department)}</div>
                </td>
                <td className="align-middle"></td>
                <td className="align-middle"></td>
              </tr>
            ))}
          </tbody>
        </table>
        <AddTeamMember/>
      </div>
    </>
  )
}

export default Users
